export type NavbarKey = 'prayer' | 'daily-word' | 'playlist'

export type NavbarItem = {
  key: NavbarKey
  label: string
  description?: string
  icon: string
  path: string
}

export const useNavbar = () => {
  const { t } = useI18n()
  const { navbar } = useAppConfig()

  const links = computed(() =>
    Object.fromEntries(
      Object.entries(navbar).map(([key, { path, icon }]) => {
        return [
          key,
          {
            key,
            label: t(`navbar.${key}.label`),
            description: t(`navbar.${key}.description`),
            icon,
            path,
          },
        ]
      }),
    ),
  )

  return {
    links,
  }
}

export default useNavbar
